(function () {
	'use strict';

	angular
		.module('app')
		.factory('googleCalendar', [
			'googleCalendarConfig',
			'$location',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'utilities',
			'dataStore',
			'manageFetch',
			googleCalendar,
		]);

	function googleCalendar(
		googleCalendarConfig,
		$location,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		utilities,
		dataStore,
		manageFetch
	) {
		//Create our connection for google services
		var connection = createConnection();
		var eventEditQueue = {};
		var primarySource;
		var primarySourceTemplate;

		gBk.setErrorReporter(errorReporter);

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			auth: auth,
			switchAccount: switchAccount,
			deauthorize: deauthorize,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			assignColor: assignColor,
			updateRepeatingEventUntil: updateRepeatingEventUntil,
			deleteRepeatingInstance: deleteRepeatingInstance,
			deleteSourceEvent: deleteSourceEvent,
			updateSourceEvent: updateSourceEvent,
			getRepeatingRule: getRepeatingRule,
			repeatingConfig: repeatingConfig,
			onSignOut: onSignOut,
		};

		function getConfig() {
			return googleCalendarConfig.config();
		}

		function getFieldMap() {
			return googleCalendarConfig.fieldMap();
		}

		function getUnusedMap() {
			return googleCalendarConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return googleCalendarConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return googleCalendarConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return googleCalendarConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection() {
			var connection = googleCalendarConfig.apiConnection();

			gBk.settings.config.client_id = connection.clientID;
			gBk.calendarApiKey = connection.calendarApiKey;
			gBk.settings.config.scope = connection.accessScope;

			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message = 'There was a problem communicating with Google.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function repeatingConfig() {
			return {
				repeatEnabled: true,
				schedulesEnabled: 'all',
				footers: {never: true},
				intervalShows: {all: true},
				createOnly: false,
				yearlyMultiSelect: true,
				dateChangeClear: false,
			};
		}

		function getRepeatingRule(request, ruleObject) {
			return {recurrence: gBk.rRule(request, ruleObject)};
		}

		function updateSourceEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			callback
		) {
			editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				null,
				processResult,
				event.schedule,
				event.recurringEventID
			);
			function processResult(data) {
				callback(event);
			}
		}

		function deleteSourceEvent(event, callback) {
			var calendarId = event.schedule.id;
			gBk.getEvent(
				calendarId,
				event.recurringEventID,
				null,
				deleteSourceEvent
			);
			function deleteSourceEvent(data) {
				gBk.deleteEvent(calendarId, data.id, processDelete);
			}
			function processDelete(data) {
				if (data) {
					message =
						'There was an error deleting the event: ' +
						data.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				callback();
			}
			function confirmError() {
				return;
			}
		}

		function deleteRepeatingInstance(event, callback) {
			//get source event
			var calendarId = event.schedule.id;

			gBk.getEvent(calendarId, event.recurringEventID, null, updateEvent);

			function updateEvent(data) {
				var idTimestamp;

				var idSplit = event.eventID.split('_');

				if (idSplit && idSplit.length === 2) {
					idTimestamp = idSplit[1];
				}

				var tz;
				if (data.start.dateTime) {
					tz = data.start.timeZone;
				}
				var exceptions = 'EXDATE';

				if (
					idTimestamp &&
					(moment(
						idTimestamp,
						'YYYYMMDDTHHmmss[Z]',
						true
					).isValid() ||
						moment(idTimestamp, 'YYYYMMDD', true).isValid())
				) {
					exceptions += ':';
					exceptions += idTimestamp;
				} else {
					exceptions += tz ? ';TZID=' + tz + ':' : ':';
					exceptions += tz
						? moment.tz(event.start, tz).format('YYYYMMDDTHHmmss')
						: event.start.format('YYYYMMDD');
				}

				//we can just push new ones here, e.g. don't need to keep track of an existing.
				var recurrence = data.recurrence;
				recurrence.push(exceptions);

				var request = {recurrence: recurrence};
				gBk.updateEvent(
					calendarId,
					data.id,
					request,
					null,
					event.schedule.fieldMap,
					runCallback
				);
				function runCallback(data) {
					callback(data);
				}
			}
		}

		function updateRepeatingEventUntil(event, untilMoment, callback) {
			//get source event
			var calendarId = event.schedule.id;
			var deleteRule = false;
			gBk.getEvent(calendarId, event.recurringEventID, null, updateEvent);
			//create request with updated rule
			function updateEvent(data) {
				var sourceEvent = data;
				var firstInstance = false;
				var sourceStart;
				if (data.start.date) {
					sourceStart = moment(data.start.date);
				} else {
					sourceStart = moment(data.start.dateTime);
				}
				if (
					sourceStart.format('YYYYMMDD') ===
					event.start.format('YYYYMMDD')
				) {
					//if this is the first instance, then we want to handle differently
					callback({
						firstInstance: true,
						recurringEventID: event.recurringEventID,
					});
					return;
				}
				var recurrenceLength = data.recurrence.length;
				var rule = data.recurrence[recurrenceLength - 1];
				//remove current until
				var ruleArray = rule.split(';');
				var newArray = [];
				for (var i = 0; i < ruleArray.length; i++) {
					if (ruleArray[i].indexOf('UNTIL') === -1) {
						newArray.push(ruleArray[i]);
					}
				}
				rule = newArray.join(';');
				var untilDate = untilMoment.format('YYYYMMDD');
				var until = ';UNTIL=' + untilDate;
				rule += until;
				var recurrence = JSON.parse(JSON.stringify(data.recurrence));
				recurrence.splice(recurrenceLength - 1, 1, rule);
				var request = firstInstance
					? {recurrence: null}
					: {recurrence: recurrence};
				gBk.updateEvent(
					calendarId,
					data.id,
					request,
					null,
					event.schedule.fieldMap,
					runCallback
				);
				function runCallback(data) {
					sourceEvent.eventID = sourceEvent.id;
					callback(sourceEvent);
				}
			}
		}

		//For indexbd data store
		function createCalendarListStore(calendarList) {
			var output = {};
			output.nextSyncToken = calendarList.nextSyncToken;
			output.calendars = calendarList.items;
			return output;
		}

		function cloneObjectForStore(obj) {
			var output = {};
			for (var property in obj) {
				output[property] = obj[property];
			}
			return output;
		}
		function cloneArrayForStore(arr) {
			var output = [];
			for (var i = 0; i < arr.length; i++) {
				output.push(cloneObjectForStore(arr[i]));
			}
			return output;
		}

		function mergeArrays(array1, array2) {
			var match;
			for (var i = 0; i < array1.length; i++) {
				match = false;
				for (var ii = 0; ii < array2.length; ii++) {
					if (array1[i].id === array2[ii].id) {
						array2[ii] = array1[i];
						match = true;
					}
				}
				if (!match) {
					array2.push(array1[i]);
				}
			}
			return array2;
		}
		//End indexDB

		function auth(callback, source, statusOnly, forceConsent) {
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}
			gBk.auth(
				user.id,
				source.id,
				statusOnly,
				forceConsent,
				connection.authIsRedirect,
				connection.authRedirectFunction,
				callback
			);
		}

		function switchAccount(callback, sourceTemplate) {
			auth(
				function (authSuccess) {
					if (!authSuccess) {
						return;
					}
					deauthorize(
						function () {
							sourceTemplate.authFunction(callback);
						},
						true,
						sourceTemplate
					);
				},
				null,
				false,
				true
			);
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource;
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');

			var retainedSchedules = [];
			var removedSchedules = [];
			gBk.deauthorize(user.id, source.id, saveToken, function () {
				// Remove auth status
				sourceTemplate.status.authed = false;

				// Only try to update calendar if it exists - Not used when initiate from settings
				if (seedcodeCalendar.get('view')) {
					// Remove Schedules and events
					for (var i = 0; i < schedules.length; i++) {
						if (schedules[i].sourceID !== source.id) {
							retainedSchedules.push(schedules[i]);
						} else {
							removedSchedules.push(schedules[i]);
						}
					}

					seedcodeCalendar.init('schedules', retainedSchedules);

					for (var i = 0; i < removedSchedules.length; i++) {
						// Remove event sources from fullcalendar
						calendarElement.fullCalendar(
							'removeEventSource',
							removedSchedules[i].source,
							removedSchedules[i].id
						);

						//Remove event source from eventSourcesObject
						if (
							eventSources.indexOf(removedSchedules[i].source) >
							-1
						) {
							eventSources.splice(
								eventSources.indexOf(
									removedSchedules[i].source
								),
								1
							);
						}
					}

					// Remove orphanes
					var events = calendarElement.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (events[i].schedule.sourceID === source.id) {
							calendarElement.fullCalendar(
								'removeEvents',
								events[i]
							);
						}
					}
				}

				if (callback) {
					callback();
				}
			});
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = gBk.getUserProfile();
			var source;

			primarySourceTemplate = sourceTemplate;

			//Locate our dayback source data for google calendar
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];
					if (userProfile) {
						source.name = userProfile.name;
						source.email = userProfile.email;
					}
					//Run our authorization
					primarySource = source;

					auth(authCallBack, source, true);
					break;
				}
			}

			return [];

			function authCallBack(auth) {
				if (auth) {
					getCalendarListStore();
					// gBk.calendarList(calendarListCallBack);
				} else {
					callback(false, sourceTemplate);
				}
				sourceTemplate.status.authed = auth;
			}

			function getCalendarListStore() {
				processCalendarListStore({target: {result: null}});
				// return;
				// dataStore.get('calendars', sourceTemplate.id, processCalendarListStore)
			}
			function processCalendarListStore(e) {
				var options = {};
				// var storeResult = e.target.result;
				// if (storeResult && storeResult.nextSyncToken) {
				//   //options.syncToken = storeResult.nextSyncToken;
				// }
				gBk.calendarList(calendarListCallBack, options);
				// // calendarListCallBack({items: []});
				function calendarListCallBack(calendarList) {
					// If no source is specified or no calendars are returned
					if (!source || !calendarList || !calendarList.items) {
						callback([], sourceTemplate);
						return;
					}

					applySchedules(calendarList.items, source.id);

					// if (calendarList.error) {
					//   //ToDo: we need to set something here that says we ran this already so we don't go in a loop
					//   gBk.calendarList(calendarListCallBack);
					//   return;
					// }
					// var fullCalendarList;

					// if (false && storeResult && storeResult.items.length && calendarList.items.length) {
					//   fullCalendarList = mergeArrays(calendarList.items, storeResult.items)
					// }
					// else if (false && storeResult && storeResult.items.length ) {
					//   fullCalendarList = storeResult.items;
					// }
					// else {
					//   fullCalendarList = calendarList.items;
					// }
					// var saveData = {nextSyncToken: calendarList.nextSyncToken, items: cloneArrayForStore(fullCalendarList)};
					// if(fullCalendarList){
					//   dataStore.update('calendars', saveData, sourceTemplate.id, indexDBCallback)
					//   //applySchedules(calendarList.items);
					// }
					// else {
					//   callback(false, sourceTemplate);
					//   utilities.showMessage('There was a problem retrieving Google Calendars. Try refreshing the page to resolve this issue.', 0, 8000);
					// }
					// function indexDBCallback(data) {
					//   applySchedules(fullCalendarList);
					// }
				}
			}

			function applySchedules(schedules, sourceID) {
				var scheduleID;
				//Execute our test
				//indexDBTest(sourceTemplate.id, schedules);

				//Clean schedule data
				for (var i = 0; i < schedules.length; i++) {
					//Merge source data that is missing from the returned calendar / schedule
					for (var property in source) {
						if (schedules[i][property] === undefined) {
							schedules[i][property] = source[property];
						}
					}

					schedules[i].sourceID = sourceID;

					//Add dayback source data to schedules because this is not a 1:1 source type
					schedules[i].customFields = source.customFields
						? JSON.parse(JSON.stringify(source.customFields))
						: null;
					schedules[i].customActions = source.customActions
						? JSON.parse(JSON.stringify(source.customActions))
						: null;
					schedules[i].eventActions = source.eventActions
						? JSON.parse(JSON.stringify(source.eventActions))
						: null;
					schedules[i].fieldMap = source.fieldMap
						? JSON.parse(JSON.stringify(source.fieldMap))
						: null;
					schedules[i].unusedMap = source.unusedMap
						? JSON.parse(JSON.stringify(source.unusedMap))
						: null;
					schedules[i].allowHTMLMap = source.allowHTMLMap
						? JSON.parse(JSON.stringify(source.allowHTMLMap))
						: null;
					schedules[i].labelMap = source.labelMap
						? JSON.parse(JSON.stringify(source.labelMap))
						: null;

					scheduleID = utilities.stringToID(schedules[i].id);

					if (source[scheduleID]) {
						//Append schedule specific items to already specified source items
						for (var property in source[scheduleID]) {
							if (
								schedules[i][property] &&
								(property === 'eventActions' ||
									property === 'customActions' ||
									property === 'customFields' ||
									property === 'fieldMap' ||
									property === 'unusedMap' ||
									property === 'allowHTMLMap' ||
									property === 'labelMap')
							) {
								for (var calendarProperty in source[scheduleID][
									property
								]) {
									if (
										property === 'fieldMap' ||
										property === 'unusedMap' ||
										property === 'allowHTMLMap' ||
										property === 'labelMap'
									) {
										if (
											!schedules[i][property][
												calendarProperty
											]
										) {
											schedules[i][property][
												calendarProperty
											] =
												source[scheduleID][property][
													calendarProperty
												];
										}
									} else {
										schedules[i][property][
											calendarProperty
										] =
											source[scheduleID][property][
												calendarProperty
											];
									}
								}
							} else {
								schedules[i][property] =
									sourceTemplate.settings[property] &&
									!sourceTemplate.settings[property]
										.scheduleOnly &&
									source[property] !== 'disable-global'
										? source[property]
										: source[scheduleID][property];
							}
						}
					}

					//Set source specific custom field properties
					if (schedules[i].customFields) {
						for (var property in schedules[i].customFields) {
							schedules[i].customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}

					calendarIO.cleanSchedule(schedules[i], sourceTemplate);

					//Normalize read only
					schedules[i].editable =
						schedules[i].accessRole !== 'reader' &&
						schedules[i].accessRole !== 'freeBusyReader';

					//Normalize the calendar name
					schedules[i].name =
						schedules[i].summaryOverride && !schedules[i].editable
							? schedules[i].summaryOverride
							: schedules[i].summary;
				}
				callback(schedules, sourceTemplate);
			}
		}

		function changeScheduleName(name, callback, schedule) {
			var request;
			if (schedule.editable) {
				request = {summary: name};
				gBk.updateCalendar(schedule.id, request, function (result) {
					callback(result);
				});
			} else {
				request = {summaryOverride: name};
				gBk.updateCalendarList(schedule.id, request, function (result) {
					callback(result);
				});
			}

			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			var request = {
				backgroundColor: color,
				foregroundColor: '#000000',
			};
			gBk.updateCalendarList(schedule.id, request, function (result) {
				callback(result);
			});
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function getUnscheduled(callbackFunc, schedule) {
			var fieldMap = schedule.fieldMap;
			var calendarId = schedule.id;

			var callback = manageFetch.create(
				schedule.id,
				'unscheduled',
				callbackFunc
			).confirm;
			//make sure we have field info for this object ig includeFilters is specified

			if (!schedule.allowUnscheduled) {
				callback();
				return;
			}

			gBk.eventList(calendarId, fieldMap, processEvents, {
				sharedExtendedProperty: 'dBk_unscheduled=true',
			});

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}
				callback(mutateEvents(result.items, schedule));
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var fieldMap = schedule.fieldMap;
			var queryDate = calendarElement.fullCalendar('getDate');

			var calendarId = schedule.id;
			var queryStart = moment(start).format();
			var queryEnd = moment(end).format();
			var queryDayRange = end.diff(start, 'days');

			var storeID = schedule.id + ' - ' + queryStart + ' - ' + queryEnd;

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			//dataStore.get('events', storeID, processEventsStore);
			processEventsStore();
			function processEventsStore(e) {
				var storeResult = {};
				var customizedRepeats =
					(config.automaticRepetitions && queryDayRange <= 45) ||
					view.name !== 'basicHorizon'
						? false
						: true;
				var options = {
					singleEvents: !customizedRepeats,
				};
				if (e && e.target) {
					storeResult = e.target.result;
				}
				if (false && storeResult && storeResult.nextSyncToken) {
					callback(mutateEvents(storeResult.items, schedule));
					options.syncToken = storeResult.nextSyncToken;
				} else {
					options.timeMax = queryEnd;
					options.timeMin = queryStart;
				}

				// Run before events fetched action
				const actionCallbacks = {
					confirm: function () {
						gBk.eventList(
							calendarId,
							fieldMap,
							processEvents,
							options
						);
					},
					cancel: function () {
						callback(mutateEvents([], schedule, customizedRepeats));
					},
				};

				const actionResult = calendarIO.runEventActions(
					'beforeEventsFetched',
					{schedule: schedule},
					true,
					actionCallbacks,
					null
				);

				if (!actionResult) {
					return;
				}

				gBk.eventList(calendarId, fieldMap, processEvents, options);
				// processEvents({items: []});
				function processEvents(result) {
					if (!result || result.error) {
						schedule.error = result.error;
						callback();
						return;
					}
					// var fullEventList;
					// if (false && storeResult && storeResult.items.length && result.items.length) {
					//   fullEventList = mergeArrays(result.items, storeResult.items)
					// }
					// else if (false && storeResult && storeResult.items.length ) {
					//   fullEventList = storeResult.items;
					// }
					// else {
					//   fullEventList = result.items;
					// }
					// var saveData = {nextSyncToken: result.nextSyncToken, items: cloneArrayForStore(fullEventList)};
					// //dataStore.update('events', saveData, storeID, eventStoreCallback);
					// function eventStoreCallback(data) {
					//   console.log('event success');
					// }

					//callback is the built in full calendar event callback. This will write the events we just got to the view.
					callback(
						mutateEvents(result.items, schedule, customizedRepeats)
					);
				}
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return JSON.stringify(item);
			} else {
				return item;
			}
		}

		function getRRuleFreq(rule) {
			var afterFreq = rule.split('FREQ=');
			if (!afterFreq.length) {
				return afterFreq[0].split(';')[0];
			} else {
				return afterFreq[1].split(';')[0];
			}
		}

		function rruleToDates(
			event,
			recurrence,
			recurrenceFrequency,
			rangeStart,
			rangeEnd,
			dayRange
		) {
			var recurrenceRule;
			var recurrenceType;
			var recurrenceStartDiff;

			var ruleFromString;

			// alert(rrule.rrulestr('DTSTART:20120201T023000Z\nRRULE:FREQ=MONTHLY;COUNT=5'));

			// recurrenceType = recurrenceFrequency === 'DAILY' ? 'DAY' : recurrenceFrequency.slice(0, -2);

			// recurrenceStartDiff = moment(event.start).diff(moment(rangeStart).subtract(1, 'day'), recurrenceType);

			recurrenceRule = recurrence;
			recurrenceRule +=
				'\nDTSTART:' +
				moment(event.start)
					.hours(0)
					.minutes(0)
					.seconds(0)
					.utc()
					.format('YYYYMMDDTHHmmss[Z]'); // Subtract a day to make inclusive
			// recurrenceRule += ';DTSTART=' + '20191028T190000Z';
			// recurrenceRule += ';UNTIL=' + moment(rangeEnd).utc().format('YYYYMMDD');
			// ruleFromString = rrule.RRule.fromString(recurrenceRule);

			ruleFromString = rrule.rrulestr(recurrenceRule);
			// Return array of dates
			return ruleFromString.between(
				rangeStart.toDate(),
				rangeEnd.toDate(),
				'inc=true'
			);
		}

		function processMutateEvents(
			start,
			end,
			dayRange,
			event,
			output,
			mutate,
			customizedRepeats,
			recurringEventPositionMatrix,
			hiddenRecurringEventMatrix
		) {
			var config = seedcodeCalendar.get('config');

			var repeatingDates;
			var repeatingEvent;
			var repeatingEventDuration;

			var recurrenceExDate;
			var recurrenceExDateTZ;
			var recurrenceExDates;
			var recurrenceExDatesCleaned = [];

			var recurrenceResult;
			var recurrence;
			var recurrenceFrequency;

			var recurringEventIDMatrix = {};
			var recurringEventIterator = 0;

			var recurrenceIDFormat = event.allDay
				? 'YYYYMMDD'
				: 'YYYYMMDDTHHmmss[Z]';

			if (
				customizedRepeats &&
				!event.recurringEventID &&
				event.recurrence &&
				event.recurrence.length
			) {
				recurrenceResult = [];

				for (var ii = 0; ii < event.recurrence.length; ii++) {
					if (event.recurrence[ii].indexOf('EXDATE;') > -1) {
						recurrenceExDate =
							event.recurrence[ii].split('EXDATE;');
						if (recurrenceExDate.length) {
							if (recurrenceExDate[1].indexOf('TZID=') > -1) {
								recurrenceExDate = recurrenceExDate[1]
									.split('TZID=')[1]
									.split(':');
								recurrenceExDateTZ = recurrenceExDate[0];
							} else if (
								recurrenceExDate[1].indexOf('VALUE=DATE') > -1
							) {
								recurrenceExDate = recurrenceExDate[1]
									.split('VALUE=DATE')[1]
									.split(':');
								recurrenceExDateTZ = null;
							}

							recurrenceExDates = recurrenceExDate[1]
								? recurrenceExDate[1].split(',')
								: [];

							//Get DTStart and modify the dates returned to the same time as that

							for (
								var iii = 0;
								iii < recurrenceExDates.length;
								iii++
							) {
								if (recurrenceExDateTZ) {
									recurrenceExDatesCleaned.push(
										moment
											.tz(
												recurrenceExDates[iii],
												recurrenceExDateTZ
											)
											.hours(0)
											.minutes(0)
											.seconds(0)
											.utc()
											.format('YYYYMMDDTHHmmss[Z]')
									);
								} else {
									recurrenceExDatesCleaned.push(
										moment(recurrenceExDates[iii])
											.hours(0)
											.minutes(0)
											.seconds(0)
											.utc()
											.format('YYYYMMDDTHHmmss[Z]')
									);
								}
							}
						}

						event.recurrence[ii] =
							'EXDATE:' + recurrenceExDatesCleaned.join(',');
					}
					recurrenceResult.push(event.recurrence[ii]);
				}

				recurrence = recurrenceResult.join('\n');
				recurrenceFrequency = getRRuleFreq(recurrence);

				// Todo we need to set a repeating ID to a hash that tells us this is not being shown so if there is a break out eve
				if (
					((config.automaticRepetitions && dayRange > 45) ||
						config.hideDailyRepetitions) &&
					recurrenceFrequency === 'DAILY'
				) {
					repeatingDates = [];
					hiddenRecurringEventMatrix[event.eventID] = true;
				} else if (
					((config.automaticRepetitions && dayRange > 75) ||
						config.hideWeeklyRepetitions) &&
					recurrenceFrequency === 'WEEKLY'
				) {
					repeatingDates = [];
					hiddenRecurringEventMatrix[event.eventID] = true;
				} else if (
					((config.automaticRepetitions && dayRange > 280) ||
						config.hideMonthlyRepetitions) &&
					recurrenceFrequency === 'MONTHLY'
				) {
					repeatingDates = [];
					hiddenRecurringEventMatrix[event.eventID] = true;
				} else if (
					((config.automaticRepetitions && dayRange > 2410) ||
						config.hideYearlyRepetitions) &&
					recurrenceFrequency === 'YEARLY'
				) {
					repeatingDates = [];
					hiddenRecurringEventMatrix[event.eventID] = true;
				} else {
					repeatingDates = rruleToDates(
						event,
						recurrence,
						recurrenceFrequency,
						start,
						end,
						dayRange
					);
				}

				var eventStart = moment(event.start);
				var eventEnd = moment(event.end);

				// Get the duration of the event so we can keep that duration when creating the repeats
				repeatingEventDuration = moment(eventEnd).diff(
					moment(eventStart)
				);
				for (var iii = 0; iii < repeatingDates.length; iii++) {
					repeatingEvent = JSON.parse(JSON.stringify(event));
					repeatingEvent.recurrence = [];
					repeatingEvent.recurringEventID = event.eventID;
					repeatingEvent.start = moment(
						moment(repeatingDates[iii]).utc().format()
					)
						.hours(eventStart.hours())
						.minutes(eventStart.minutes());
					repeatingEvent.end = moment(repeatingEvent.start).add(
						repeatingEventDuration
					);
					repeatingEvent.eventID =
						event.eventID +
						'_' +
						repeatingEvent.start.utc().format(recurrenceIDFormat);

					// Build repeat id matrix
					recurringEventIDMatrix[repeatingEvent.eventID] =
						recurringEventIterator;
					recurringEventIterator++;

					// Build a matrix to check individial repeats (repeats that have been separated from the original)
					recurringEventPositionMatrix[repeatingEvent.eventID] =
						output.length;

					output.push(mutate(repeatingEvent));
				}
			} else {
				if (
					customizedRepeats &&
					event.recurringEventID &&
					hiddenRecurringEventMatrix[event.recurringEventID]
				) {
					// Do nothing here for now as the repetition is supposed to be hidden
				} else if (
					customizedRepeats &&
					event.recurringEventID &&
					recurringEventIDMatrix[event.eventID]
				) {
					output[recurringEventIDMatrix[event.eventID]] =
						mutate(event);
				} else if (
					customizedRepeats &&
					event.recurringEventID &&
					recurringEventPositionMatrix[event.eventID]
				) {
					output[recurringEventPositionMatrix[event.eventID]] =
						mutate(event);
				} else {
					recurringEventIterator++;
					output.push(mutate(event));
				}
			}
		}

		function mutateEvents(events, schedule, customizedRepeats) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}
			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var output = [];

			var view = seedcodeCalendar.get('view');
			var start = view.startOverride || view.start;
			var end = view.end;
			var dayRange = end.diff(start, 'days');

			var recurringEventPositionMatrix = {};
			var hiddenRecurringEventMatrix = {};

			for (var i = 0; i < events.length; i++) {
				processMutateEvents(
					start,
					end,
					dayRange,
					events[i],
					output,
					mutate,
					customizedRepeats,
					recurringEventPositionMatrix,
					hiddenRecurringEventMatrix
				);
			}

			return output;
			function mutate(event) {
				//We can use this to create a recurring event in FullCalendar
				// if (event.recurringEventID) {
				//   event.id = event.recurringEventID;
				// }
				event.eventSource = schedule.id;
				event.schedule = schedule;
				event.color = schedule.backgroundColor;
				event.textColor = schedule.foregroundColor;
				//field specific transformations

				//convert status to array
				event.status = event.status === '' ? [] : event.status;

				//convert resource to array
				event.resource =
					!event.resource || event.resource === ''
						? []
						: event.resource;

				// //convert contact to array
				event.contactName =
					!event.contactName || event.contactName === ''
						? []
						: event.contactName.split('\n');
				event.contactID =
					!event.contactID || event.contactID === ''
						? []
						: event.contactID.split('\n');

				// //convert project to array
				event.projectName =
					!event.projectName || event.projectName === ''
						? []
						: event.projectName.split('\n');
				event.projectID =
					!event.projectID || event.projectID === ''
						? []
						: event.projectID.split('\n');

				//deduct one day from end of all day Events
				if (event.allDay && event.end && event.end !== event.start) {
					event.end = moment(event.end)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}

				// Apply timezone if necessary
				event.start = $.fullCalendar.createTimezoneTime(
					event.start,
					event.allDay
				);
				event.end = $.fullCalendar.createTimezoneTime(
					event.end,
					event.allDay
				);

				// Assign title based on free / busy
				//Normalize read only
				if (
					schedule.accessRole === 'freeBusyReader' &&
					!event.titleEdit
				) {
					event.titleEdit = 'busy';
				}

				//return mulated event
				return calendarIO.cleanEvent(event, true);
			}
		}

		// Process title calculation
		function processTitle(schedule) {
			var fieldMap = schedule.fieldMap;
			//clean up entry
			title = title
				.replace(/[\n\r]/g, '')
				.replace(/, +/g, ',')
				.replace(/ +,/g, ',');

			var re;
			var titleDataArray = [];
			var resultArray = [];
			var titleArray = title ? title.split(',') : [];
			var populated;

			for (var i = 0; i < titleArray.length; i++) {
				populated = false;
				for (var field in fieldMap) {
					var thisField = fields[field];
					re = new RegExp(thisField, 'g');
					if (titleArray[i].indexOf(thisField) !== -1) {
						fieldResult = formatDateTime(
							utilities.htmlEscape(
								getFieldValue(thisField, event)
							)
						);
						if (fieldResult) {
							populated = true;
							titleDataArray[i] = titleArray[i].replace(
								re,
								fieldResult
							);
						} else {
							titleDataArray[i] = titleArray[i].replace(re, '');
						}
					}
				}
				if (populated) {
					resultArray.push(addMatch(titleDataArray[i]));
				}
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule,
			sourceEventId
		) {
			var config = seedcodeCalendar.get('config');
			var customFields = schedule.customFields;
			var allDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;
			var fieldMap = schedule.fieldMap;
			var editObj = {};
			var additionalEditProperties = {};
			var until;
			var view;

			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is a new event write all non empty fields
				if (
					property !== 'eventID' &&
					property !== 'title' &&
					changes.hasOwnProperty(property)
				) {
					//format start and stop as needed
					//don't populate these if we're doing a source event
					if (
						(property === 'start' || property === 'end') &&
						!sourceEventId
					) {
						editObj[property] = changes.allDay
							? moment(changes[property].format('YYYY-MM-DD'))
							: moment(changes[property]);
					} else if (
						property === 'resource' ||
						property === 'status'
					) {
						editObj[property] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (var i = 0; i < editObj[property].length; i++) {
							if (editObj[property][i] === config.noFilterLabel) {
								editObj[property][i] = '';
							}
						}
					} else if (property === 'tags') {
						editObj[property] = calendarIO.packageTagsOutput(
							event[property]
						);
					} else if (customFields && customFields[property]) {
						editObj[fieldMap[property]] =
							calendarIO.customFieldOutputTransform(
								property,
								changes[property],
								customFields,
								schedule,
								customFieldMutateOutput
							);
						//Adjust custom field data types to strings
						editObj[fieldMap[property]] =
							editObj[fieldMap[property]] === undefined
								? ''
								: editObj[fieldMap[property]].toString();
					} else if (property === 'eventSource') {
						//don't do anything as we don't want to add eventSource to editObj
					}
					//Join arrays
					else if (Array.isArray(event[property])) {
						editObj[property] = changes[property].slice(); //Use slice here to clone the array rather than using a reference
					} else {
						editObj[property] = changes[property];
					}
				}
			}

			//we're editing the source event (of repeating events)
			var IdToUse;
			if (sourceEventId) {
				IdToUse = sourceEventId;
			} else {
				IdToUse = event.eventID;
			}

			//Explicitely set timezone as it is currently a non standard field
			editObj.timezone = schedule.timeZone;
			//Apply additional event properties necessary to properly edit the event
			additionalEditProperties.allDay = event.allDay;
			additionalEditProperties.attendees = event.attendees;

			if (editObj.start) {
				editObj.start = $.fullCalendar.timezoneTimeToLocalTime(
					editObj.start,
					allDay
				);
			}
			if (editObj.end) {
				editObj.end = $.fullCalendar.timezoneTimeToLocalTime(
					editObj.end,
					allDay
				);
			}

			//Check if we should be changing the calendar
			if (IdToUse && changes.eventSource) {
				//Change calendar
				gBk.changeCalendar(
					IdToUse,
					revertObject.eventSource,
					changes.eventSource,
					fieldMap,
					changeCalendarSuccess
				);
			}
			//If not just apply our event changes
			else {
				//Edit the event
				applyEditEvent();
			}

			//Callback for changing the calendar
			function changeCalendarSuccess(result) {
				if (result && result.error) {
					resultCallback(result);
				} else {
					//Edit the event
					applyEditEvent();
				}
			}
			//Apply our event result (write result data)
			function applyEditEvent() {
				//if we have an id we're updating
				if (event.eventID) {
					if (event.recurringEventID || editObj.recurrence) {
						view = seedcodeCalendar.get('view');
						until = moment(view.end.format());
					} else {
						until = false;
					}
					gBk.updateEvent(
						schedule.id,
						IdToUse,
						editObj,
						additionalEditProperties,
						fieldMap,
						resultCallback,
						null,
						null,
						until
					);
				} else {
					if (editObj.recurrence) {
						view = seedcodeCalendar.get('view');
						until = moment(view.end.format());
					} else {
						until = false;
					}
					if (
						editObj.hasOwnProperty('unscheduled') &&
						!editObj.unscheduled
					) {
						delete editObj.unscheduled;
					}
					gBk.createEvent(
						schedule.id,
						editObj,
						additionalEditProperties,
						fieldMap,
						resultCallback,
						null,
						until
					);
				}
			}
			function resultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var aResult = !result.items ? [result] : result.items;
				var message;
				var modified;
				var domEvents;
				var newSeries;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result && result.error) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(result);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						//applyEventChanges(event, revertObject); //Would revert the event but we don't want that in this case as we don't want to lose changes
						message =
							'There was an error and your event could not be saved: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return;
				}

				//No errors found continue with updating the event with the returned data
				aResult = mutateEvents(aResult, schedule);

				if (until) {
					element = seedcodeCalendar.get('element');
					domEvents = element.fullCalendar('clientEvents');
				}

				if (editObj.recurrence && editObj.recurrence.length > 0) {
					newSeries = true;
				}

				for (var i = 0; i < aResult.length; i++) {
					eventResult = aResult[i];

					eventResult.allDay = event.allDay;
					eventResult._allDay = eventResult.allDay;

					if (aResult.length === 1 || (newSeries && i === 0)) {
						// Normalize dates
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'buildEventDates',
								eventResult,
								eventResult
							);

						modified = applyEventChanges(event, eventResult);
						if (modified) {
							fullCalendarBridge.update(element, event);
						}
					} else if (newSeries) {
						seedcodeCalendar
							.get('element')
							.fullCalendar('renderEvent', eventResult);
					} else {
						for (var ii = 0; ii < domEvents.length; ii++) {
							if (eventResult.eventID === domEvents[ii].eventID) {
								//case 49365. There's a difference when fullCalendarBridge.update(element, domEvents[ii]);
								//is used that causes a change false positive on "end" when opened and closed in the popover
								//working around this by removing and re-rendering - small performance difference
								element.fullCalendar(
									'removeEvents',
									domEvents[ii]
								);
								seedcodeCalendar
									.get('element')
									.fullCalendar('renderEvent', eventResult);
							}
						}
					}
				}

				if (callback) {
					callback(event);
				}

				//Define error functions for this edit
				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result.error);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			//Check if we need to apply a new schedule
			if (changes.schedule && event.schedule.id !== changes.schedule.id) {
				modified = true;
				event.schedule = changes.schedule;
			}
			//Loop through all properties and set those
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'start' &&
					property !== 'end'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (
					moment.isMoment(changes[property]) ||
					property === 'start' ||
					property === 'end'
				) {
					if (
						!moment(changes[property]).isSame(
							moment(event[property])
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			gBk.deleteEvent(schedule.id, event.eventID, processDelete);

			function processDelete(result) {
				var message;
				if (result) {
					message =
						'There was an error deleting the event: ' +
						result.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				calendarIO.confirmDelete(event, 0, callback);
			}
			function confirmError() {
				return;
			}
		}

		function assignColor(event) {
			var colors = googleCalendarConfig.colors();

			if (!event.colorId) {
				return false;
			}

			for (var property in colors) {
				if (event.colorId == property) {
					event.color = colors[property].background;
					return colors[property].background;
				}
			}
		}

		function onSignOut() {
			gBk.clearTokenMemory();
		}
	}
})();
